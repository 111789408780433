@import 'theme.css';
@import 'markdown.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

.setting-btn-group {
  line-height: 20px;
  padding: 12px;
  text-decoration: none;
}

.setting-btn-group-active {
  color: var(--primary-color-dark);
  background: var(--background-primary-soft);
  border-color: var(--primary-color-soft) !important;
}
.button-primary {
  color: var(--primary-color);
  background: var(--background-primary-soft);
  border: 1px solid var(--primary-color) !important;
}
.button-primary:hover {
  color: var(--primary-color-dark);
  background: var(--primary-color-soft);
  border: 1px solid var(--primary-color) !important;
}
.ant-select-single input {
  border-color: rgb(241 245 249 / var(--tw-border-opacity)) !important;
  align-items: center;
  height: 100% !important;
}
.ant-select-single input:focus {
  outline: none;
  box-shadow: none;
}
.ant-select-single .ant-select-selector {
  align-items: center;
  border-radius: var(--border-radius) !important;
  border-color: #eaecf0 !important;
}
.key-table .ant-table-thead > tr > th,
.key-table .ant-table-thead > tr > td {
  background: #eaecf0;
  color: #667085;
}
.key-table .ant-table-thead > tr:first-child > *:first-child {
  border-bottom-left-radius: 8px;
}
.key-table .ant-table-thead > tr > td {
  border-bottom-right-radius: 8px;
}
.key-table .ant-table-tbody > tr > td {
  border: none !important;
}
.key-table .ant-table {
  background: transparent !important;
  box-shadow: none !important;
}

.modal-style .ant-modal-content {
  padding: 0px !important;
}
.modal-style .ant-modal-header {
  padding: 10px;
  border-bottom: 1px solid #eaecf0;
  background: #eaecf0;
  margin-bottom: 0px !important;
}
.modal-style .ant-modal-close {
  top: 10px;
  inset-inline-end: 7px;
}
.bot-tabs .ant-tabs-nav-wrap {
  flex-direction: column;
  background: #fff;
  border-radius: var(--border-radius);
  padding: 5px;
}
.bot-tabs .ant-tabs-nav-operations {
  display: none !important;
}
.bot-tabs .ant-tabs-tab {
  width: 50%;
  border: 0px !important;
  min-height: 40px;
  border-radius: var(--border-radius) !important;
}
.bot-tabs .ant-tabs-tab {
  background: transparent !important;
  text-align: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
}
.bot-tabs .ant-tabs-tab .ant-tabs-tab-btn {
  color: var(--primary-color-soft) !important;
}

.bot-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--primary-color) !important;
}
.bot-tabs .ant-tabs-tab.ant-tabs-tab-active {
  background: var(--primary-color-soft) !important;
  color: var(--primary-color) !important;
}

.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--primary-color) !important;
}

.ant-tabs .ant-tabs-tab-btn:hover {
  color: var(--primary-color) !important;
}

.ant-tabs-ink-bar {
  background-color: var(
    --primary-color
  ) !important; /* Change the color of the bottom line */
}

.bot-custom-checkbox input {
  padding: 0;
  height: 100%;
  width: 100%;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}
.bot-custom-checkbox {
  position: relative;
  cursor: pointer;
  min-height: 85px;
  align-items: center;
  border: 1px solid #eaecf0;
}
.bot-custom-checkbox i {
  color: #667085;
  font-size: 20px;
  margin-bottom: 5px;
}
input:checked + .bot-custom-checkbox i {
  color: var(--primary-color) !important;
}
input:checked + .bot-custom-checkbox span {
  color: var(--primary-color);
}
input:checked + .bot-custom-checkbox {
  border-color: var(--primary-color);
  background-color: #eeeffc !important;
}

.knowledge-checkbox input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.knowledge-checkbox label {
  position: relative;
  cursor: pointer;
}

.knowledge-checkbox label:before {
  content: '';
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid #0079bf;
  box-shadow:
    0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  height: 16px;
  width: 16px;
  border-radius: 6px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-top: -3px;
  margin-right: 5px;
}

.knowledge-checkbox input:checked + label:before {
  border-color: #1c2dd8;
  background: #eeeffc;
}

.knowledge-checkbox input:checked + label:after {
  content: '';
  display: block;
  position: absolute;
  top: 6px;
  left: 6px;
  width: 5px;
  height: 8px;
  border: solid #1c2dd8;
  background: #eeeffc;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.bot-form-tab .ant-tabs-nav {
  position: absolute !important;
  top: -52px;
  left: 15px;
}

.bot-form-tab .ant-tabs-nav-operations {
  display: none !important;
}
.bot-form-tab .ant-tabs-tab {
  width: auto;
  border: 0px !important;
  min-height: 40px;
  border-radius: 10px !important;
}
.bot-form-tab .ant-tabs-tab {
  background: transparent !important;
  text-align: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
}
.bot-form-tab .ant-tabs-tab .ant-tabs-tab-btn {
  color: var(--primary-color-soft) !important;
}

.bot-form-tab .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--primary-color) !important;
}
.bot-form-tab .ant-tabs-tab.ant-tabs-tab-active {
  background: var(--primary-color-soft) !important;
  color: var(--primary-color) !important;
}
.bot-form-tab .ant-tabs-nav::before {
  display: none !important;
}
.ck-editor__editable_inline {
  min-height: 400px;
}

.min-h-530 {
  min-height: 530px;
}

.min-h-400 {
  min-height: 400px;
}

.bg-dot:after {
  width: 100%;
  height: 100%;
  background-image: radial-gradient(circle at center, black 1px, transparent 0);
  background-size: 16px 16px;
  background-repeat: round;
}

.bg-dot-center-mask {
  -webkit-mask-image: radial-gradient(
    100% 50% at 50% 115%,
    #ffffff 0%,
    rgba(255, 255, 255, 0.3) 30%,
    transparent 100%
  );
  mask-image: radial-gradient(
    100% 50% at 50% 115%,
    #ffffff 0%,
    rgba(255, 255, 255, 0.3) 30%,
    transparent 100%
  );
}

.dot-absolute:after {
  content: '.';
  position: absolute;
}

.prose :where(ul > li):not(:where([class~='not-prose'] *))::marker {
  color: #000 !important;
}

.relevance-score .ant-slider-mark .ant-slider-mark-text:first-child {
  transform: translateX(-10%) !important;
}
.relevance-score .ant-slider-mark .ant-slider-mark-text:last-child {
  transform: translateX(-90%) !important;
}

.center-aligned-icon .ant-btn-icon-only {
  display: flex;
  align-items: center;
  justify-content: center;
}

.bg-primary-soft {
  background: hsl(var(--primaryColorHsl), 100%, 97%);
}

.animate-fade {
  animation: fade 2s infinite;
}

[type='text']:focus {
  box-shadow: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}

.ant-input-outlined:focus {
  box-shadow: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}

.ant-input-outlined:hover {
  box-shadow: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}

.ant-input-outlined:focus-within {
  box-shadow: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}

.ant-upload-drag:hover {
  box-shadow: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}

.role-dropdown .ant-select-selector {
  border-radius: 0.75rem !important;
}

.delete-file {
  top: 5%;
  left: 95%;
  transform: translate(-50%, -50%);
}

.ant-radio-button-wrapper {
  height: 46 !important;
}

.ant-collapse {
  background: #ffffff;
}

.embed .ant-tabs-nav-list {
  background-color: #f3f4f6;
  border-radius: 0.5rem !important;
}

.conversation .ant-tabs-nav-list {
  background-color: #f3f4f6;
  border-radius: 0.5rem !important;
}

.conversation .ant-tabs {
  margin-top: 0px !important;
}

.conversation .ant-tabs-nav-wrap {
  padding: 0px;
}

.pt-3px {
  padding-top: 3px;
}

.shadow-widget {
  --tw-shadow: 0 2px 4px rgba(0, 18, 26, 0.08), 0 3px 12px rgba(0, 18, 26, 0.16),
    0 2px 14px 0 rgba(0, 18, 26, 0.2);
  --tw-shadow-colored: 0 2px 4px var(--tw-shadow-color),
    0 3px 12px var(--tw-shadow-color), 0 2px 14px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.content-description {
  max-width: 500px;
}

.knowledge-card .ant-card-head {
  padding: 0 !important;
  margin: 0 !important;
  background: none !important;
  border-bottom: none !important;
}

.knowledge-card .ant-card-body,
.knowledge-card .ant-card-bordered,
.knowledge-card .ant-card {
  padding: 0 !important;
  margin: 0 !important;
  background: none !important;
  border: none !important;
  border: transparent !important;
}

.knowledge-card .ant-card-head-title {
  padding-bottom: 8px;
}
.knowledge-Table .ant-pagination {
  display: flex;
  justify-content: center;
}

.ant-form-item-control-input {
  margin-top: 0.5rem;
  background-color: #f0f0f0;
}

.ant-select-focused .ant-select-selector {
  border-color: var(--primary-color) !important;
  border-width: 1px !important;
}

@keyframes fade {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

.controls:has(.dd-opened) {
  opacity: 100 !important;
}

.controls:has(.dd-opened) * a.flex.cursor-pointer {
  @apply bg-gray-200;
}

.hover-item {
  @apply hover:!text-[var(--primary-color)] hover:!bg-[var(--primary-color-soft)] hover:!border-[var(--primary-color)];
}

.hover-item-default {
  @apply hover:!text-[var(--primary-color)];
}

.ant-error-notification .ant-message-notice-content {
  @apply !bg-red-50 !border-red-500 !text-red-800;
}

@keyframes zoomIn {
  0% {
    transform: scale(0.9);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.file-uploader.visible {
  opacity: 1;
  z-index: 9999;
  animation: zoomIn 0.3s ease-out forwards;
  -webkit-animation: zoomIn 0.3s ease-out forwards;
}

.ant-btn-primary.custom,
.custom-table-wrapper .ant-checkbox-checked .ant-checkbox-inner,
.custom-table-wrapper .ant-checkbox-indeterminate .ant-checkbox-inner,
.custom-table-wrapper .ant-checkbox-indeterminate .ant-checkbox-inner::after,
.custom-table-wrapper .ant-checkbox-indeterminate:hover .ant-checkbox-inner {
  background-color: var(--primary-color) !important;
}

.custom-table-wrapper .ant-checkbox:hover .ant-checkbox-inner,
.custom-table-wrapper .ant-checkbox-checked .ant-checkbox-inner,
.custom-table-wrapper .ant-checkbox-indeterminate:hover .ant-checkbox-inner {
  border-color: var(--primary-color) !important;
}

.bg-transparent-form-input .ant-form-item-control-input {
  background-color: transparent !important;
}

.bg-transparent-upload-list .ant-upload-list-item-container {
  background-color: white;
}

.bg-primary-details {
  background-color: var(--background-color-details);
}

.custom-drag .ant-upload-drag {
  @apply !bg-[#7F56D9] !bg-opacity-5 hover:!bg-[#7F56D9] hover:!bg-opacity-20 !border-dashed !border-[#7F56D9] !border-opacity-40 hover:!border-[#7F56D9] hover:!border-opacity-100;
}

.custom-table-header .ant-table-thead {
  font-size: 12px;
}

.custom-form-item-label .ant-form-item-label {
  padding: 0 0 !important;
}

.horizontal-form-item .ant-row {
  align-items: center;
}

.ant-input-outlined {
  @apply !border-gray-200;
}
.ant-input-status-error {
  @apply !border-[#ff4d4f];
}

.custom-skeleton-loader .ant-skeleton-button {
  @apply !min-h-14;
}

.custom-label-style .ant-form-item-label {
  @apply !m-0 !p-0 !font-semibold;
}
.custom-item-input .ant-form-item-control-input {
  @apply !m-0;
}

.container-close-wiget {
  transition: background-color 200ms ease;
}

.container-close-wiget:hover {
  background-color: #11111112;
}

.custom-color-picker .ant-color-picker-color-block-inner {
  @apply !rounded-full;
}
.custom-color-block .ant-color-picker-color-block {
  @apply !rounded-full;
}
.custom-picker-trigger-text .ant-color-picker-trigger-text {
  margin: 0;
}

.custom-radio-button-wrapper .ant-radio-button-wrapper {
  height: 40px;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  z-index: 1;
  color: var(--primary-color) !important;
  background: #ffffff;
  border-color: var(--primary-color) !important;
  &:hover {
    color: var(--primary-color) !important;
  }
  &::before {
    background-color: var(--primary-color) !important;
  }
}

.ant-radio-button-wrapper:hover {
  position: relative;
  color: var(--primary-color) !important;
}

.ant-radio-button-inner .ant-radio-button-input {
  color: var(--primary-color) !important;
  &:hover {
    color: var(--primary-color) !important;
  }
}

.custom-ant-collapse-content .ant-collapse-content {
  @apply border-none !border-t-[1px] !border-gray-500 border-solid border-opacity-10;
}
.custom-ant-card-body .ant-card-body {
  @apply !px-4 !py-3;
}

@layer utilities {
  .scrollbar {
    overflow: auto;
  }

  .scrollbar::-webkit-scrollbar {
    width: 16px;
    background-color: transparent;
  }

  .scrollbar::-webkit-scrollbar-thumb {
    height: 35%;
    background-color: #d1d5db;
    border: 4px solid white;
    border-radius: 100dvh;
  }

  .scrollbar::-webkit-scrollbar-thumb:hover {
    background: #9ca3af;
  }
}
